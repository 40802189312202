import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { css, cx } from '@emotion/css';
import { TLM } from 'utils/type';

interface PassProp {
  data: {
    sigDet: TLM;
    sigPwr: TLM;
    loopStress: TLM;
    carrierLock: TLM;
    detLock: TLM;
    bitLock: TLM;
    cmdDecode: TLM;
  };
}

// Memoized Handle component to prevent unnecessary re-renders
const Handles = React.memo(() => {
  const handleStyle = useMemo(() => ({ opacity: 0 }), []);
  
  return (
    <>
      <Handle type="target" position={Position.Top} id="target-top" style={handleStyle} />
      <Handle type="target" position={Position.Left} id="target-left" style={handleStyle} />
      <Handle type="target" position={Position.Right} id="target-right" style={handleStyle} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={handleStyle} />
      <Handle type="source" position={Position.Top} id="source-top" style={handleStyle} />
      <Handle type="source" position={Position.Left} id="source-left" style={handleStyle} />
      <Handle type="source" position={Position.Right} id="source-right" style={handleStyle} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={handleStyle} />
    </>
  );
});

// Memoized Indicator component
const Indicator = React.memo(({ name, fill }: { name: string; fill: string }) => {
  return (
    <div className={indicatorStyles}>
      <p>{name}</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="300px"
        height="100px"
        viewBox="-0.5 -0.5 300 100"
      >
        <g>
          <ellipse
            cx="150"
            cy="50"
            rx="20"
            ry="20"
            fill={fill}
            stroke="#FFFFFF"
            strokeWidth="5"
            pointerEvents="all"
          />
        </g>
      </svg>
    </div>
  );
});

// Memoized Arrow component
const Arrow = React.memo(({ fill, isLast, isActive }: { fill: string; isLast: boolean; isActive: boolean }) => {
  return (
    <div className={cx('arrow-container', { 'active': fill === 'green' })}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="300px"
        height="50px"
        viewBox="-0.5 -0.5 300 50"
      >
        <g>
          <line 
            x1="0" y1="25" x2="300" y2="25" 
            stroke={fill} 
            strokeWidth="5" 
            className={cx('animated-line', { 'active': isActive })}
          />
        </g>
      </svg>
    </div>
  );
});

const PassIndicator: React.FC<PassProp> = React.memo(({ data }) => {
  const [cmdDecodeFill, setCmdDecodeFill] = useState('gray');
  const prevCmdDecodeValue = useRef<number | null>(null);
  const lastUpdateTime = useRef<number>(Date.now());

  // Memoize fill calculations
  const fills = useMemo(() => {
    const sigDetFill = data.sigDet?.telemetry === 'NODETECT' ? 'gray' : 'green';
    const carrierLockFill = data.carrierLock?.telemetry === 'NOLOCK' ? 'gray' : 'green';
    const detLockFill = data.detLock?.telemetry === 'NOLOCK' ? 'gray' : 'green';
    const bitLockFill = data.bitLock?.telemetry === 'NOLOCK' ? 'gray' : 'green';

    let loopStressFill = 'gray';
    if (data.loopStress) {
      const loopStressValue = parseFloat(data.loopStress.telemetry);
      if (loopStressValue === 0 && detLockFill === 'green') {
        loopStressFill = 'green';
      }
    }

    return {
      sigDetFill,
      carrierLockFill,
      detLockFill,
      bitLockFill,
      loopStressFill
    };
  }, [data.sigDet, data.carrierLock, data.detLock, data.bitLock, data.loopStress]);

  // Memoize command decode effect
  useEffect(() => {
    if (!data.cmdDecode) return;

    const currentValue = parseInt(data.cmdDecode.telemetry);
    const currentTime = Date.now();
    
    if (prevCmdDecodeValue.current !== null) {
      if (currentValue > prevCmdDecodeValue.current && 
          (currentTime - lastUpdateTime.current) <= 30000) {
        setCmdDecodeFill('green');
      } else {
        setCmdDecodeFill('gray');
      }
    }
    
    prevCmdDecodeValue.current = currentValue;
    lastUpdateTime.current = currentTime;
  }, [data.cmdDecode]);

  // Memoize indicators array
  const indicators = useMemo(() => [
    { name: 'Signal Detect', fill: fills.sigDetFill },
    { name: 'Signal Lock', fill: fills.detLockFill },
    { name: 'Loop Stress', fill: fills.loopStressFill },
    { name: 'Carrier Lock', fill: fills.carrierLockFill },
    { name: 'Bitsync Lock', fill: fills.bitLockFill },
    { name: 'Command Decoded', fill: cmdDecodeFill }
  ], [fills, cmdDecodeFill]);

  // Memoize allGreen calculation
  const allGreen = useMemo(() => 
    Object.values(fills).every(fill => fill === 'green') && cmdDecodeFill === 'green',
    [fills, cmdDecodeFill]
  );

  return (
    <div className={containerStyles}>
      <Handles />
      
      <div className={rowStyles}>
        {indicators.map((indicator) => (
          <Indicator
            key={indicator.name}
            name={indicator.name}
            fill={indicator.fill}
          />
        ))}
      </div>

      <div className={cx(flowStyles, rowStyles)}>
        {indicators.map((indicator, index) => (
          <Arrow
            key={`arrow-${index}`}
            fill={indicator.fill}
            isLast={index === indicators.length - 1}
            isActive={allGreen}
          />
        ))}
      </div>
    </div>
  );
});

// Styles moved outside component to prevent recreation
const flowStyles = css`
  @keyframes flowDash {
    to {
      stroke-dashoffset: 60;
    }
  }
  .animated-line {
    stroke-dasharray: 30 30;
  }
  .animated-line.active {
    animation: flowDash 1s linear infinite;
  }
  .arrow-container {
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  .arrow-container.active {
    opacity: 1;
  }
`;

const containerStyles = css`
  width: 100%;
`;

const rowStyles = css`
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
`;

const indicatorStyles = css`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    margin: 0 0 10px 0;
  }
`;

export default PassIndicator;