import React from 'react'
import { css } from '@emotion/css'

const GlideCommPanel: React.FC = () => {

  const panelContainer = css`
  width: 4150px;
  height: 1950px;
  background-color: rgb(38, 38, 38);
  border: 6px solid rgb(13, 57, 152);
  text-align: left;`

  return (
    <div className={panelContainer}>
    </div>
  )
}

export default GlideCommPanel;
