// createNodes.ts

import { Field, LocationData, TelemetryDictionary } from 'utils/type';
import { Node } from 'reactflow';

export const createNodes = (
  telemetryDictionary: TelemetryDictionary,
  pbTime: string,
  pbLoop: number,
  groundList: {SC: any;
    GS: any;
    AOS: string;
    LOS: string;
    Tr: any;},
  locationData: LocationData
) => {
  const nodes: Node[] = [
    { id: 'Panel', type: 'panel', position: { x: 0, y: 50 }, data: {} },
    { id: 'mv1', type: 'modelViewer', parentNode: 'Panel', position: { x: 2750, y: 950 }, data: {'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4']}},

    { id: 'Ground Subsystem', type: 'ground', parentNode: 'Panel', position: { x: 1800, y: 1200 }, data: {} },
    { id: 'Antennas', type: 'antennas', parentNode: 'Ground Subsystem', position: { x: 100, y: 100 }, data: { name: '', 'info': groundList, 'linfo': locationData } },

    { id: 'MOC', type: 'moc', parentNode: 'Panel', position: { x: 50, y: 1200 }, data: {} },
    { id: 'VR2', type: 'bar', parentNode: 'MOC', position: { x: -50, y: 150 }, data: { } },

    { id: 'CDH Subsystem', type: 'cdh', parentNode: 'Panel', position: { x: 50, y: 50 }, data: {} },
    { id: 'Tele Subsystem', type: 'tele', parentNode: 'Panel', position: { x: 1450, y: 50 }, data: {} },

    { id: 'S-Band Transponder', type: 'glideCommSBT', parentNode: 'Tele Subsystem', position: { x: 50, y: 300 }, data: { power: telemetryDictionary['Transponder PRI Pwr'], power1: telemetryDictionary['Transponder RED Pwr'], className: 'sbContainer', value: {} } },
    { id: 'Receiver', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 1250, y: 50 }, data: { name: 'Receiver', className: 'rxContainer', value: { 'Op Mode': { 'dbData': telemetryDictionary['Rx Mode'], 'fname': 'Receiver Operating Mode', 'mname': 'rxopmodest' }, 'Cmd Decrypt': { 'dbData': telemetryDictionary['Command Decrypt'], 'fname': 'Command Decrypt' }, 'Cmd Auth Fail': { 'dbData': telemetryDictionary['Command Auth Fail'], 'fname': 'Command Authentication Fail Count' }, 'TE-Key Select': { 'dbData': telemetryDictionary['TE-Key Selected'] } } } },
    { id: 'Transceiver', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 600, y: 50 }, data: { name: 'Transceiver', className: 'tcContainer', value: { 'TX Group Plug': { 'dbData': telemetryDictionary['TX Installed'], 'fname': 'TX Group ENBL PLUG INSTALLED' }, 'Pwr Amp Temp': { 'dbData': telemetryDictionary['Pwr Amp Temp'], 'fname': '' }, 'Mod Temp': { 'dbData': telemetryDictionary['Mod Temp'], 'fname': '' }, 'Init Rout Status': { 'dbData': telemetryDictionary['Init Rout Status'], 'fname': '' }, 'Init Rout Result': { 'dbData': telemetryDictionary['Init Rout Result'], 'fname': '' }, 'Init Result': { 'dbData': telemetryDictionary['Init Result'], 'fname': '' }, 'AD9364 Mach State': { 'dbData': telemetryDictionary['AD9364 En Machine State'], 'fname': '' }, 'Master Reset': { 'dbData': telemetryDictionary['Master Reset'], 'fname': '' }, 'Reset Status': { 'dbData': telemetryDictionary['Reset Status'], 'fname': '' } } } },
    { id: 'Transmitter', type: 'glideCommTB', parentNode: 'S-Band Transponder', position: { x: 50, y: 50 }, data: { name: 'Transmitter', power: telemetryDictionary['Transmitter PRI Pwr'], power1: telemetryDictionary['Transmitter RED Pwr'], value: { 'Pwr': { 'dbData': telemetryDictionary['Transmitter Pwr'], 'fname': 'Measurement of the transmitter power' }, '18V/28V': { 'dbData': telemetryDictionary['Transmitter 18/28'], 'fname': 'Transmitter 18V/28V secondary' }, 'Mode': { 'dbData': telemetryDictionary['Transmitter Mode'], 'fname': 'Transmitter Operating Mode' }, 'RF State': { 'dbData': telemetryDictionary['RF Enable'], 'fname': 'Status of RF Enable command' }, 'Fwd Pwr Out': { 'dbData': telemetryDictionary['Transponder Fwd Pwr'], 'fname': 'Narrowband Transponder Fwd Power Out' }, 'Coherency En': { 'dbData': telemetryDictionary['Coherency Enabled'], 'fname': 'Coherency Enabled' }, 'Conv Status': { 'dbData': telemetryDictionary['Conv Status'], 'fname': 'Status of the Tx Conv Code Command' }, 'Nrz Status': { 'dbData': telemetryDictionary['Nrz Status'], 'fname': 'Status of the Tx Nrz Conv On Command' }, } } },
    { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Tele Subsystem', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },
    { id: 'ScSketch', type: 'scSketch', parentNode: 'Tele Subsystem', position: { x: 1925, y: 150 }, data: { name: '', lgaSwitch:telemetryDictionary['Tx Switch LGA'],mgaSwitch:telemetryDictionary['Tx Switch MGA']}},

    { id: 'SMOAB', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 675, y: 125 }, data: { name: 'Standard Multi-Operation Avionics Board(SMOAB)', value: { 'HW Apt Cnt': { 'dbData': telemetryDictionary['HW Apt Cnt'], 'fname': 'HW SOH VC0 Command accept' }, 'HW Rj Cnt': { 'dbData': telemetryDictionary['HW Rj Cnt'], 'fname': 'HW SOH VC0 Command Reject' }, 'SBE Err Cnt': { 'dbData': telemetryDictionary['SBE Cnt'], 'fname': 'Single Bit Error Count' }, 'TMR Err Cnt': { 'dbData': telemetryDictionary['TMR Cnt'], 'fname': 'Flash TMR Errors Count' }, "UL Rand Status": { 'dbData': telemetryDictionary['Moab Uplink Rand Status'], 'fname': '' }, "DL Rand Status": { 'dbData': telemetryDictionary['Moab Downlink Rand Status'], 'fname': '' }, 'WD ISR Status': { 'dbData': telemetryDictionary['SMOAB WatchDog ISR Task Status'], 'fname': '' }, 'WD MBE Status': { 'dbData': telemetryDictionary['SMOAB WatchDog MBE Task Status'], 'fname': '' }, 'WD SBE Status': { 'dbData': telemetryDictionary['SMOAB WatchDog SBE Task Status'], 'fname': '' }, 'WD TLM Status': { 'dbData': telemetryDictionary['SMOAB WatchDog TLM Task Status'], 'fname': '' } } } },
    { id: 'SSMB', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 25, y: 500 }, data: { name: 'Solids State Memory Board(SSMB)', className: 'ssmbContainer', value: { 'SCI Read St': { 'dbData': telemetryDictionary['SSR Read Status'], 'fname': 'SCI current read State' }, 'SCI Pbk Time': { 'dbData': telemetryDictionary['SCI Pbk Time'], 'fname': '' }, 'SSOH Read St': { 'dbData': telemetryDictionary['SSOH Read State'], 'fname': 'SSR current read State' }, 'SSOH Pbk Time': { 'dbData': telemetryDictionary['SSOH Pbk Time'], 'fname': '' }, 'WD Status': { 'dbData': telemetryDictionary['SSMB WatchDog Task Status'], 'fname': '' } } } },
    { id: 'RAD750', type: 'glideCommTB', parentNode: 'CDH Subsystem', position: { x: 25, y: 50 }, data: { name: 'Single Board Computer(SBC)', value: { 'FSW Apt Cnt': { 'dbData': telemetryDictionary['FSW Apt Cnt'], 'fname': '' }, 'FSW Rj Cnt': { 'dbData': telemetryDictionary['FSW Rj Cnt'], 'fname': '' }, 'CBM Active': { 'dbData': telemetryDictionary['CBM Active'], 'fname': '' }, 'CSM Status': { 'dbData': telemetryDictionary['CSM Status'], 'fname': '' } } } },

    { id: 'InfoBox', type: 'vmodeBox', parentNode: '', position: { x: 0, y: -175 }, data: { name: '', playBackValue: { time: pbTime, loop: pbLoop }, 'qx':telemetryDictionary['Q1'],'qy':telemetryDictionary['Q2'],'qz':telemetryDictionary['Q3'],'qs':telemetryDictionary['Q4'] } },

  ];

  return nodes;
};
