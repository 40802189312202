import React, { useEffect, useState } from 'react';
import { css, cx, keyframes } from '@emotion/css';
import { InnerData, TLM, VisibleTooltips } from 'utils/type';
import PlotlyChart from './PlotlyChart';
import { determineColorNew, handleCopyMneName } from 'utils/function';
import * as scheme from './EPSScheme'; // Import your styles

interface ReactionWheelProp {
  data: {
    name: string;
    value: InnerData;
    className: string;
    power: TLM;
    speed: TLM;
  };
}

const generateRotateKeyframes = (rotationAngle: number) => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(${rotationAngle}deg);
  }
`;

const ReactionWheel: React.FC<ReactionWheelProp> = ({ data }) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips((prevVisibleTooltips) => ({
      ...prevVisibleTooltips,
      [key]: true,
    }));

    setTimeout(() => {
      setVisibleTooltips((prevVisibleTooltips) => ({
        ...prevVisibleTooltips,
        [key]: false,
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops((prevVisibleLimitPops) => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key],
    }));
  };
  
  const rotationSpeed = Math.abs(parseFloat(data.speed?.telemetry) || 0);
  const rotationDirection = (parseFloat(data.speed?.telemetry) || 0) >= 0 ? 1 : -1;
  const rotationAngle = rotationDirection * 360;

  useEffect(() => {
    const currentValue = data.speed && data.speed.telemetry ? parseFloat(data.speed.telemetry) : null;
    if (!currentValue) {
      return
    }

  }, [data.speed]);

  const rotationAnimation = generateRotateKeyframes(rotationAngle);

  const powerOn = data.power?.telemetry === 'ON';

  const wheelStyles = css`
    .wheel-group {
      transform-origin: 75px 75px;
      animation: ${rotationSpeed > 0 && powerOn ? `${rotationAnimation} 2s linear infinite` : 'none'};
    }
  `;

  return (
    <div>
    <div
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200px"
        height="200px"
        viewBox="-0.5 -0.5 200 200"
        className={wheelStyles}
      >
        <circle cx="100" cy="100" r="90" fill="#E0E0E0" stroke="#333" strokeWidth="3" />
        <circle cx="100" cy="100" r="70" fill="#C0C0C0" stroke="#000" strokeWidth="2" />

        <g className="wheel-group">
          {/* Rotation axis */}
          <line x1="100" y1="10" x2="100" y2="190" stroke="#444" strokeWidth="4" />

          {/* Mounting points */}
          <circle cx="100" cy="20" r="10" fill="#666" stroke="#000" strokeWidth="2" />
          <circle cx="100" cy="180" r="10" fill="#666" stroke="#000" strokeWidth="2" />
        </g>

        {/* Directional arrows */}
        {/* - arrow */}
        <line
          x1="50"
          y1="100"
          x2="80"
          y2="100"
          stroke={rotationDirection < 0 && powerOn? "#009900" : "#444"} /* Green if negative */
          strokeWidth="4"
        />
        {/* + arrow */}
        <line
          x1="120"
          y1="100"
          x2="150"
          y2="100"
          stroke={rotationDirection > 0 && powerOn ? "#009900" : "#444"} /* Green if positive */
          strokeWidth="4"
        />
        <line
          x1="135"
          y1="85"
          x2="135"
          y2="115"
          stroke={rotationDirection > 0 && powerOn ? "#009900" : "#444"} /* Green if positive */
          strokeWidth="4"
        />
      </svg>
    </div>
    <p>{data.name}</p>
    <div className={scheme.svgValueColCon}>
        {data.value &&
          Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && (
                  <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                    {innerData.dbData.mne}
                  </span>
                )}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>
                  {innerData.fname}
                </span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`
                    color: ${innerData.dbData?.live};
                  `,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && (
                <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>
              )}
              {visibleLimitPops[key] && (
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReactionWheel;
