import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { css, cx } from '@emotion/css';
import { SatelliteData, TestScheduleData, dividerStyle, dividerStyle1, findColorForStation, infoBoxStyle, nameToMne, nowLineStyle, satelliteBarStyle, satelliteNameStyle, satelliteNameStyle1, satelliteRowStyle, timeLabelStyle1, timeMarkStyle, timelineStyle } from './utils';
import { useTimeSetting } from './TimeSettingContext';
import { dateConfig } from 'constant/dateConfig';
import { useTimeRangeContext } from './TimeRangeContext';

type TimeLineProps = {
  data: Record<string, TestScheduleData>;
  onTimeRangeChange: (start: moment.Moment, end: moment.Moment) => void; // Callback to notify the parent
  selectedTimeRange: [moment.Moment, moment.Moment] | null;
  zoomRange: [moment.Moment, moment.Moment] | null;
  setZoomRange: (range: [moment.Moment, moment.Moment] | null) => void;
};

const TimeLine: React.FC<TimeLineProps> = ({ data, onTimeRangeChange, selectedTimeRange, zoomRange, setZoomRange }) => {
  const [visibleInfoPops, setVisibleInfoPops] = useState<Record<string, boolean>>({});
  //const [currentTime, setCurrentTime] = useState(moment())
  const [currentTime, setCurrentTime] = useState(moment.utc())
  const [hoverPosition, setHoverPosition] = useState<number | null>(null);
  const [isOnBar, setIsOnBar] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState<number | null>(null);
  const [dragEndX, setDragEndX] = useState<number | null>(null);
  const [dragDistance, setDragDistance] = useState(0);
  
  const enableZoom = true; // Set this to `true` when you want to enable zoom again.

  const { timeSetting1, setTimeSetting1 } = useTimeSetting(); // Access timeSetting here

  const config = useMemo(() => dateConfig[timeSetting1], [timeSetting1]);
  const { lengthTime, lengthUnit, lengthCount, intervalTime, intervalUnit, previosTime, previosUnit } = config;

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment().utc()), 30000) // Update  every 30s  
    return () => clearInterval(timer)
  }, [])

  const timelineStart = useMemo(
    () => (zoomRange ? zoomRange[0] : currentTime.clone().subtract(previosTime, previosUnit).startOf(intervalUnit)),
    [currentTime, zoomRange, previosTime, previosUnit, intervalUnit]
  );
  
  const timelineEnd = useMemo(
    () => (zoomRange ? zoomRange[1] : currentTime.clone().add(parseInt(lengthTime, 10), lengthUnit).startOf(intervalUnit)),
    [currentTime, zoomRange, lengthTime, lengthUnit, intervalUnit]
  );
  
  const totalDuration = useMemo(() => timelineEnd.diff(timelineStart, 'seconds'), [timelineEnd, timelineStart]);
  
  const adjustedTimelineStart = useMemo(
    () => (zoomRange ? zoomRange[0] : timelineStart),
    [zoomRange, timelineStart]
  );
  
  const adjustedTimelineEnd = useMemo(
    () => (zoomRange ? zoomRange[1] : timelineEnd),
    [zoomRange, timelineEnd]
  );
  
  const adjustedTotalDuration = useMemo(
    () => adjustedTimelineEnd.diff(adjustedTimelineStart, 'seconds'),
    [adjustedTimelineEnd, adjustedTimelineStart]
  );
  
  const generateTimeLabels = useCallback(() => {
    const labels = [];
    const totalIntervals = 10; // Maximum number of labels (adjust as needed)
    const intervalSeconds = adjustedTotalDuration / totalIntervals; // Divide zoom range into equal intervals
  
    const currentTime = adjustedTimelineStart.clone();
  
    for (let i = 0; i <= totalIntervals; i++) {
      labels.push(currentTime.clone());
      currentTime.add(intervalSeconds, 'seconds');
    }
  
    return labels;
  }, [adjustedTimelineStart, adjustedTotalDuration]);

  const timeLabels = useMemo(() => generateTimeLabels(), [generateTimeLabels])

  const nowLinePosition = useMemo(() => {
    const startTime = timeLabels[0]
    const totalMinutes = moment.duration(lengthTime, lengthUnit).asMinutes()
    const currentMinutes = currentTime.diff(startTime, 'minutes')
    
    return `${(currentMinutes / totalMinutes) * 100}%`
  }, [currentTime, timeLabels, lengthTime, lengthUnit])

  const calculateBarPosition = (tAos: number, tLos: number) => {
    const aosSeconds = Math.max(moment.unix(tAos).diff(adjustedTimelineStart, 'seconds'), 0); // Clip start
    const losSeconds = Math.min(moment.unix(tLos).diff(adjustedTimelineStart, 'seconds'), adjustedTotalDuration); // Clip end
  
    if (losSeconds <= 0 || aosSeconds >= adjustedTotalDuration) {
      return null; // Bar is completely outside the visible range
    }
  
    const leftPercentage = (aosSeconds / adjustedTotalDuration) * 100;
    const widthPercentage = ((losSeconds - aosSeconds) / adjustedTotalDuration) * 100;
  
    return { leftPercentage, widthPercentage };
  };
  

  const handleValueChartClick = (key: string) => {
    setVisibleInfoPops(prev => ({ 
      ...prev,
      [key]: !prev[key]
    }));
  };

  // Function to calculate gaps
  const calculateGaps = () => {
    let allPasses: Array<{ start: number; end: number }> = [];

    Object.values(data).forEach(satelliteData => {
      const tAos_list = satelliteData.tAos_Value.split(',').map(Number);
      const tLos_list = satelliteData.tLos_Value.split(',').map(Number);

      console.log('tAos_list', tAos_list);

      tAos_list.forEach((tAos, index) => {
        allPasses.push({ start: tAos, end: tLos_list[index] });
      });
    });

    // Sort passes by start time
    allPasses.sort((a, b) => a.start - b.start);

    // Merge overlapping passes
    const mergedPasses = allPasses.reduce((acc, curr) => {
      if (acc.length === 0) {
        return [curr];
      }
      const last = acc[acc.length - 1];
      if (curr.start <= last.end) {
        last.end = Math.max(last.end, curr.end);
      } else {
        acc.push(curr);
      }
      return acc;
    }, [] as Array<{ start: number; end: number }>);

    // Calculate gaps
    const gaps: Array<{ start: number; end: number }> = [];

    const timelineStartUnix = timelineStart.unix();
    const timelineEndUnix = timelineEnd.unix();

    console.log('timelineStartUnix', allPasses);

        // Check if mergedPasses is empty
  if (mergedPasses.length === 0) {
    // If no passes, the entire timeline is a gap
    gaps.push({ start: timelineStartUnix, end: timelineEndUnix });
    return gaps;
  }

    if (mergedPasses[0].start > timelineStartUnix) {
      gaps.push({ start: timelineStartUnix, end: mergedPasses[0].start });
    }

    for (let i = 0; i < mergedPasses.length - 1; i++) {
      gaps.push({ start: mergedPasses[i].end, end: mergedPasses[i + 1].start });
    }

    if (mergedPasses[mergedPasses.length - 1].end < timelineEndUnix) {
      gaps.push({ start: mergedPasses[mergedPasses.length - 1].end, end: timelineEndUnix });
    }

    return gaps;
  };

  const gaps = useMemo(() => calculateGaps(), [calculateGaps]);

  const handleMouseMove = (e: React.MouseEvent) => {
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - boundingRect.left; // Get X relative to the container
  
    if (isDragging && enableZoom) {
      // Handle dragging logic
      setDragEndX(x);
      setDragDistance(Math.abs(x - (dragStartX || 0)));
    } else if (isOnBar) {
      // Handle hover logic
      setHoverPosition(x);
    }
  };
  

  const handleMouseLeave = () => {
    if (!isOnBar) {
      return;
    }

    setHoverPosition(null);
  };

  const handleMouseDown = (e: React.MouseEvent) => {

    if (!enableZoom) return;

    const boundingRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - boundingRect.left;
    setDragStartX(x);
    setDragEndX(null);
    setIsDragging(true);
    setDragDistance(0);
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    setIsDragging(false);
  
    // Only proceed if zooming is enabled and a valid drag occurred
    if (enableZoom && dragDistance >= 5 && dragStartX !== null && dragEndX !== null) {
      const offset = 150; // The 150px prefix
  
      const startX = Math.max(0, Math.min(dragStartX, dragEndX) - offset); // Adjust for offset
      const endX = Math.max(0, Math.max(dragStartX, dragEndX) - offset);   // Adjust for offset
  
      // Calculate the corresponding start and end times
      //@ts-ignore
      const timelineWidth = e.currentTarget.offsetWidth - offset; // Adjust total width for offset
      const startTime = timelineStart.clone().add((startX / timelineWidth) * totalDuration, 'seconds');
      const endTime = timelineStart.clone().add((endX / timelineWidth) * totalDuration, 'seconds');
  
      // Log the corrected time range
      console.log(`Selected Time Range: Start - ${startTime.format('YYYY-MM-DD HH:mm:ss')} UTC, End - ${endTime.format('YYYY-MM-DD HH:mm:ss')} UTC`);


      onTimeRangeChange(startTime, endTime);
      // Set the zoom range
      setZoomRange([startTime, endTime]);
    }
  
    // Reset drag state
    setDragStartX(null);
    setDragEndX(null);
    setDragDistance(0);
  };
  

  return (
    <div className={timelineStyle} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp} onMouseDown={handleMouseDown}>

      <div className={css`
        display:flex;
        `}>
          

        <div className={css`width:160px`}>
          

        </div>
        <div className={cx(timeLabelStyle1, css`
        user-select: none;
        cursor: crosshair;
        `)}>

{timeLabels.map((time, index, array) => {
    const totalIntervals = timeLabels.length - 1;
    const leftPercentage = (index / totalIntervals) * 100;

    const showDate = index === 0 || time.date() !== array[index - 1].date();

    return (
      <div key={`${time.format()}-${timeSetting1}`}>
        {showDate && <span className={css`
        position: absolute;
        left: ${leftPercentage}%;
        transform: translateX(-50%);
        top: -20px;
        font-size: 0.8em;
        color: #666;
        `}>
           {time.format('DDD')}
            {/* {time.format('MMM DD')} */}
        </span>}
        <span
          className={css`
          position: absolute;
          left: ${leftPercentage}%;
          transform: translateX(-50%);
          `}
        >
          {time.format('HH:mm')}
        </span>
        <div
          className={timeMarkStyle}
          style={{ left: `${leftPercentage}%` }}
        />
      </div>
    );
  })}
          <div className={nowLineStyle} style={{ left: nowLinePosition }} />
        </div>
        {enableZoom && isDragging && dragStartX !== null && dragEndX !== null && (
          <div
            className={css`
              position: absolute;
              top: 0;
              left: ${Math.min(dragStartX, dragEndX)}px;
              width: ${Math.abs(dragEndX - dragStartX)}px;
              height: 100%;
              background-color: rgba(0, 0, 255, 0.2);
              pointer-events: none;
            `}
          />
        )}
      </div>
      {Object.entries(data).map(([satellite, satelliteData], rowIndex) => {
        const tAos_list = satelliteData.tAos_Value.split(',').map(Number);
        const tLos_list = satelliteData.tLos_Value.split(',').map(Number);
        const testDescription_list = satelliteData && satelliteData.testDescription_Value ? satelliteData.testDescription_Value.split(',') : [];

        return (
          <div key={satellite} className={cx(satelliteRowStyle, css`user-select: none;`)}>
            <div className={satelliteNameStyle1}>{satellite}</div>
            <div className={satelliteBarStyle}>
              {tAos_list.map((tAos, index) => {
                const tLos = tLos_list[index];
                const testDescription = testDescription_list.length === 0 ? '' : testDescription_list[index];

                const position = calculateBarPosition(tAos, tLos);

                // Only render if the position is valid (i.e., tAos is within 24 hours)
                if (position === null) {
                  return null;
                }

                const { leftPercentage, widthPercentage } = position;

                return (
                  <div
                    key={index}
                    className={css`
                      position: absolute;
                      left: ${leftPercentage}%;
                      width: ${widthPercentage}%;
                      height: 100%;
                      background-color: pink;
                      cursor: pointer;
                    `}
                    onClick={() => handleValueChartClick(`${satellite}-${index}`)}
                    onMouseMove={() => setIsOnBar(true)}
                    onMouseLeave={() => setIsOnBar(false)}
                  >
                    <div>
                      {visibleInfoPops[`${satellite}-${index}`] && (
                        <div className={infoBoxStyle}>
                          <p>AOS: {moment.unix(tAos).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                          {/* <p>AOS: {moment.unix(tAos).format('YYYY-MM-DD HH:mm:ss')}</p> */}
                          <p>LOS: {moment.unix(tLos).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                          <p>Test Description: {testDescription}</p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {rowIndex < Object.entries(data).length && <div className={dividerStyle1} />}
          </div>
        );
      })}

      
      {/* Gap Row */}
      <div className={cx(satelliteRowStyle,css`overflow:hidden;`)}>
        
        <div className={satelliteNameStyle1}>Idle Time</div>
        
        <div className={satelliteBarStyle}>
          
          {gaps.map((gap, index) => {
            const position = calculateBarPosition(gap.start, gap.end);
            if (position === null) {
              return null;
            }
            const { leftPercentage, widthPercentage } = position;

            return (
              <div
                key={index}
                className={css`
                  position: absolute;
                  left: ${leftPercentage}%;
                  width: ${widthPercentage}%;
                  height: 100%;
                  background-color: gray; // Gray color for gaps
                  cursor: pointer;
                  
                    `}
                onClick={() => handleValueChartClick(`Gaps-${index}`)}
                onMouseMove={() => setIsOnBar(true)}
                onMouseLeave={() => setIsOnBar(false)}
              >
                <div>
                  {visibleInfoPops[`Gaps-${index}`] && (
                    <div className={infoBoxStyle}>
                      <p>AOS: {moment.unix(gap.start).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                      <p>LOS: {moment.unix(gap.end).utc().format('YYYY-MM-DD HH:mm:ss [UTC]')}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className={dividerStyle1} />
      </div>

      {/* Line at mouse hover */}
      {hoverPosition !== null && isOnBar && (
        <div
          className={css`
            position: absolute;
            top: 0;
            left: ${hoverPosition}px; // Mouse position in pixels
            width: 2px;
            height: 100%;
            background-color: red;
            pointer-events: none;
          `}
        />
      )}

{enableZoom && zoomRange && (
        <button
          className={css`
            z-index: 10;
            background-color: #7d7d7d;
            color: white;
            border: none;
            padding: 5px 10px;
            cursor: pointer;

            &:hover {
              background-color: #555;
            }
          `}
          onClick={() => {
            setZoomRange(null);
            setTimeSetting1(timeSetting1);
            onTimeRangeChange(currentTime.clone().subtract(previosTime, previosUnit).startOf(intervalUnit), currentTime.clone().add(parseInt(lengthTime, 10), lengthUnit).startOf(intervalUnit));
          }}
        >
          Reset Zoom
        </button>
      )}
    </div>
  );
};

export default TimeLine;
