import React from 'react';
import { PanelProps} from '@grafana/data';
import { PanelDataErrorView} from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import {SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { Field } from './utils';
import { TimeRangeContext } from './TimeRangeContext';
import App from './App';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {

  const styles = useStyles2(getStyles);

  const exampleFields: Field[] = [
    { name: "", type: "", values: [], labels: {} }
  ];

  const selectedData = data.series[0] ? data.series[0].fields : exampleFields;
  const nextContact = data.series[1] ? data.series[1].fields : exampleFields;
  const colorList = data.series[2] ? data.series[2].fields : exampleFields;
  const selectedTestData = data.series[3] ? data.series[3].fields : exampleFields;

  const nextContactField = nextContact.find(field => field.name === "tAos");

  const nextContactValues = nextContactField && nextContactField.values ? nextContactField.values[0] : 0;
  
  const sqlQuery = data.series[0] ? data.series[0].meta?.executedQueryString : "";

  let source = "";

  if (sqlQuery) {
    const regex = new RegExp(`facility\\s*=\\s*'(.*?)'`, 'i');
    const match = regex.exec(sqlQuery);
    // Use 'match' here. Remember that 'match' could be null if no match is found.

    if (match) {
      source = match[1]
    }
    
  }

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }
  
  return (
    <div
      className={cx(
        styles.wrapper,
      )}
    >
    <TimeRangeContext.Provider value={{ nextContactValues, colorList }}>

        <App dbData={selectedData} dbTestScheduleData={selectedTestData} source={source} width={width} height={height}></App>

        </TimeRangeContext.Provider>

    </div>
  );
};
