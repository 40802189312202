import { css } from '@emotion/css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { Node, ReactFlowInstance, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import EPSPanel from '../module/EPSPanel';
import EpsTextBox from './TextBox';
import BattCell from './BattCell';
import SolarArray from './SolarArray';
import PlaceHolder from './PlaceHolder';
import CustomEdge from './CustomEdge';
import BcrMain from '../module/BcrMain';
import BcrSub from '../module/BcrSub';
import Actuator from './Actuator';
import EpsHTb from './EpsHTb';
import LivePlot from './LivePlot';
import Heater from './Heater';
import { useTimeRangeContext } from './TimeRangeContext';
import VModeBox from './VModeBox';
import RW from './ReactionWheel';
import DrawerComponent from './Drawer';
import { useDrawer } from 'hooks/DrawerContext';
import { createNodes } from 'utils/createNode';
import { createTelemetryDictionary } from 'utils/dataProcessFunc';
import { generateMneString } from 'utils/function';
import { Field, ProcessedData, TelemetryDictionary } from 'utils/type';
import Payload from './Payload';

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  influxData: ProcessedData;
  limitData: Field[];
  // ... other props if there are any
};

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: monospace;
}

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-epsPanel{
  z-index: 5 !important;
}

.react-flow__node-bcrMain{
  z-index: 6 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: var(--app-background);
  color: #FFFFFF;
  font-size: 35px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
`;

const nodeTypes = { payload: Payload, epsPanel: EPSPanel,rw:RW,solar: SolarArray, vmodeBox: VModeBox, liveplot: LivePlot, heater: Heater, epsHTb: EpsHTb, actuator: Actuator, bcrMain: BcrMain, bcrSub: BcrSub, battCell: BattCell, epstextbox: EpsTextBox, placeHolder: PlaceHolder }
const edgeTypes = { customEdge: CustomEdge }

const App: React.FC<AppProps> = ({ dbData, width, height, influxData, limitData }) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  const { isDrawerOpen, onClose } = useDrawer();

  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      //@ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  const telemetryDictionary = useMemo(() => {
    return createTelemetryDictionary(dbData, limitData);
  }, [dbData, limitData]);

  const initialNodes = useMemo(() => {
    return createNodes(telemetryDictionary, pbTimeRef.current, pbLoopRef.current);
  }, [telemetryDictionary]);
 
  const [nodes, setNodes] = useState<Node[]>(initialNodes);

  const keyMnePairs = useMemo(() => {
    const pairs: Record<string, string> = {};
    
    nodes.forEach(node => {
      if (node.data.value) {
        Object.entries(node.data.value).forEach(([key, data]) => {
          // @ts-ignore
          pairs[key] = data.dbData?.mne || '';
        });
      }
    });
    
    return pairs;
  }, [nodes]);

  useEffect(() => {
    let isCancelled = false;

    if (guiValues.current.pbisInPlayBack) {
      const processData = async () => {
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;

        for (let i = 0; i < totalLoops; i++) {
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
          pbTimeRef.current = key;
          const newValue = (i + 1) / totalLoops;
          pbLoopRef.current = newValue;

          // Update aggregatedData with new values
          selectedData.forEach(item => {
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: '',
                unit: '',
                spacecraft: '',
                live: 'rgb(68, 169, 241)',
              };
            }
          });

          // Create nodes from the aggregatedData object
          const newNodes = createNodes(
            aggregatedData,
            pbTimeRef.current,
            pbLoopRef.current,            
          );
          setNodes(newNodes);

          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay();
      };

      processData();

      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true;
      };
    } else {
      // When not in playback mode, update nodes with live data
      const newNodes = createNodes(
        telemetryDictionary,
        pbTimeRef.current,
        pbLoopRef.current,
      );
      setNodes(newNodes);
    }
  }, [
    influxData,
    telemetryDictionary,
    guiValues
  ]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  console.log('dafasStringString', generateMneString())

  const arrow = { type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15 };

  const initialEdges = [
    { id: 'edge1', source: 'HDRM1', sourceHandle: 'source-left', target: 'HDRM2', targetHandle: 'target-right', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, zIndex: 10 },

    { id: 'edge2', source: 'SolarArray', sourceHandle: 'source-right', target: 'BRB', targetHandle: 'target-left', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge3', source: 'SolarArray', sourceHandle: 'source-top', target: 'SACI', targetHandle: 'target-left-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge4', source: 'Battery', sourceHandle: 'source-top', target: 'BRB', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge5', source: 'BRB', sourceHandle: 'source-top', target: 'SACI', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge6', source: 'SACI', sourceHandle: 'source-left', target: 'MCLASI', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge7', source: 'SACI', sourceHandle: 'source-top-1', target: 'PAPI6-1', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge8', source: 'SACI', sourceHandle: 'source-top-2', target: 'PAPI6-2', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge9', source: 'SACI', sourceHandle: 'source-right', target: 'PAPI6-3', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge10', source: 'PAPI6-1', sourceHandle: 'source-top-1', target: 'Heater', targetHandle: 'target-bottom-2', type: 'straight', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge11', source: 'PAPI6-2', sourceHandle: 'source-top-6', target: 'Heater', targetHandle: 'target-bottom-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge12', source: 'SACI', sourceHandle: 'source-left-1', target: 'S-Band', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge13', source: 'PAPI6-1', sourceHandle: 'source-top-2', target: 'S-Band', targetHandle: 'target-bottom-3', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge14', source: 'PAPI6-2', sourceHandle: 'source-top-4', target: 'RW', targetHandle: 'target-bottom-4', type: 'straight', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge15', source: 'PAPI6-2', sourceHandle: 'source-top-5', target: 'ST', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    // {id: 'edge16', source: 'PAPI6-1',sourceHandle: 'source-top', target: 'IMU',targetHandle:'target-bottom', type:'customEdge',style: { stroke: 'white', strokeWidth:5},markerEnd:arrow,zIndex:10},
    { id: 'edge17', source: 'PAPI6-1', sourceHandle: 'source-top-3', target: 'Payload', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge18', source: 'PAPI6-2', sourceHandle: 'source-top', target: 'Payload', targetHandle: 'target-bottom', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },
    { id: 'edge19', source: 'PAPI6-3', sourceHandle: 'source-right', target: 'Payload', targetHandle: 'target-right', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5 }, markerEnd: arrow, zIndex: 10 },

  ];

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height, width }}>
        <ReactFlow
          nodes={nodes}
          onInit={onInit}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: options.X, y: options.Y, zoom: options.Zoom }}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}
        />
      </div>

      <DrawerComponent isOpen={isDrawerOpen} onClose={onClose} keyData={keyMnePairs} />

    </ReactFlowProvider>
  );

}

export default App;







