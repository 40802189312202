import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });


  let finaledge;

  //const edge1 = `M480 575.5L 510,575.5Q 520,575.5 520,565.5L 520,300Q 520,290 530,290L 2365.5,290Q 2375.5,290 2375.5,300L2375.5 571`;
  if (id === 'GtoT'){
    //const edge = `M${sourceX} ${sourceY}L ${sourceX+85},${sourceY}Q ${sourceX+95},${sourceY} ${sourceX+95},${sourceY-10}L ${sourceX+95},${sourceY-575.5}Q ${sourceX+95},${sourceY-585.5} ${sourceX+105},${sourceY-585.5}L ${targetX-10},${sourceY-585.5}Q ${targetX},${sourceY-585.5} ${targetX},${sourceY-575.5}L${targetX} ${targetY}`;
    // console.log(sourceX, sourceY, targetX, targetY);
    // console.log(edgePath);
    const edge = `M${sourceX} ${sourceY}L${sourceX+20} ${sourceY}L ${targetX+115},${sourceY}Q ${targetX+120},${sourceY} ${targetX+120},${sourceY-5}L ${targetX+120},${targetY+5}Q ${targetX+120},${targetY} ${targetX+115},${targetY}L${targetX} ${targetY}`;

    finaledge = edge;
  }
  else if (id === 'TtoG'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX+215},${sourceY}Q ${sourceX+220},${sourceY} ${sourceX+220},${sourceY+5}L ${sourceX+220},${targetY-5}Q ${sourceX+220},${targetY} ${sourceX+215},${targetY}L${targetX+20} ${targetY}L${targetX} ${targetY}`
    finaledge = edge;
  }
  else if (id === 'wfi-heater'){

    const edge = `M${sourceX} ${sourceY}L ${sourceX+150},${sourceY}Q ${sourceX+160},${sourceY} ${sourceX+160},${sourceY+10}L ${sourceX+160},${sourceY+1552.66}Q ${sourceX+160},${sourceY+1567.66} ${sourceX+150},${sourceY+1567.66}L ${targetX},${sourceY+1567.66} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div style={{
            position: 'absolute',
            transform: `translate(-50%, -100%) translate(${labelX}px,${labelY}px)`,
          }}
          className="nodrag nopan customEdge">
        </div>
      </EdgeLabelRenderer>
    </>
  );

}
