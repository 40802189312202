import { css } from '@emotion/css';

export const BcrHub = css`
  width: 900px;

  & > div {
    width: 900px;

    & > div {
      width: 420px;

    }
  }
`;

export const PayloadBox = css`
width: 2900px;

& > div {
  width: 2900px;

  & > div {
    width: 670px;

    & div:nth-of-type(2) {
      width: 250px;
    }
  }
}
`;

export const HeaterBox = css`
width: 900px;
height: 360px;

& > div {
  width: 900px;
  height: 300px;
  flex-direction: column;

  & > div {
    width: 470px;

    &:nth-of-type(6),
    &:nth-of-type(7),
    &:nth-of-type(8),
    &:nth-of-type(9),
    &:nth-of-type(10)
     {
      width: 380px;
    }

    & div:nth-of-type(2) {
      width: 180px;
    }
  }
}
`;

export const RWBox = css`
width: 2010px;

& > div {
  width: 2010px;

  & > div {
    width: 500px;

    & div:nth-of-type(2) {
      width: 180px;
    }
  }
}
`;

export const STBox = css`
width: 930px;
height: 360px;

& > div {
  width: 930px;
  height: 300px;
  flex-direction: column;

  & > div {
    width: 500px;

    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5)
     {
      width: 420px;
    }

    & div:nth-of-type(2) {
      width: 180px;
    }
  }
}
`;

// Define a type for the class keys
export type ClassKey = 'PayloadBox' | 'BcrHub' | 'HeaterBox' | 'RWBox' | 'STBox';