import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeColor = data && (data.power.telemetry === '1' || data.power.telemetry === 'High'|| data.power.telemetry === 'On' || (data.power.telemetry ? data.power.telemetry.includes('On') : false)) ? 'green' : 'white';

  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }

  let finaledge;

  if (id === 'Solar0ToBCR'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX+60},${sourceY}Q ${sourceX+65},${sourceY} ${sourceX+65},${sourceY-5}L ${sourceX+65},${targetY+5}Q ${sourceX+65},${targetY} ${sourceX+70},${targetY} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge1'){
    sourceY = sourceY + 340;
    targetY = targetY + 340;
    targetX = targetX + 300;
    const edge = `M${sourceX} ${sourceY} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge10'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${targetY+135}Q ${sourceX},${targetY+130} ${sourceX-5},${targetY+130} L${targetX+5} ${targetY+130} Q${targetX} ${targetY+130} ${targetX} ${targetY+125} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge11'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${targetY+105}Q ${sourceX},${targetY+100} ${sourceX-5},${targetY+100} L${targetX+5} ${targetY+100} Q${targetX} ${targetY+100} ${targetX} ${targetY+95} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge12'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX-130},${sourceY}Q ${sourceX-135},${sourceY} ${sourceX-135},${sourceY-5}L ${sourceX-135},${targetY+355}Q ${sourceX-135},${targetY+350} ${sourceX-140},${targetY+350} L${targetX+5} ${targetY+350} Q${targetX} ${targetY+350} ${targetX} ${targetY+195} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge17'){
    targetX = targetX - 750;
    const edge = `M${sourceX} ${sourceY}L ${sourceX},${targetY+805}Q ${sourceX},${targetY+800} ${sourceX-5},${targetY+800} L${targetX+5} ${targetY+800} Q${targetX} ${targetY+800} ${targetX} ${targetY+795} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge18'){
    sourceX = sourceX - 100;
    targetX = targetX + 150;
    const edge = `M${sourceX} ${sourceY}L ${targetX},${targetY}`
    //const edge = `M${sourceX} ${sourceY}L ${sourceX},${targetY+705}Q ${sourceX},${targetY+700} ${sourceX-5},${targetY+700} L${targetX+5} ${targetY+700} Q${targetX} ${targetY+700} ${targetX} ${targetY+695} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge19'){
    const edge = `M${sourceX} ${sourceY}L ${sourceX+545},${sourceY}Q ${sourceX+550},${sourceY} ${sourceX+550},${sourceY-5} L${sourceX+550},${targetY+5} Q${sourceX+550},${targetY} ${sourceX+545},${targetY} L${targetX} ${targetY}`;
    finaledge = edge;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={edgeStyle} />
      <EdgeLabelRenderer>
        <div style={{position: 'relative',}}>
        </div>
      </EdgeLabelRenderer>
    </>
  );

}
