import React, { useEffect, useMemo, useState } from 'react'
import { SatelliteData, buttonStyle, findColorForStation, fontStyle, legendItemStyle, legendStyle, nameToMne, reactAppWrapper, timeInfoStyle, timeLabelStyle, timeSettingStyle } from './utils';
import { css, cx } from '@emotion/css';
import moment from 'moment';
import { useTimeRangeContext } from './TimeRangeContext';
import { useTimeSetting, TimeKeys } from './TimeSettingContext';
import { dateConfig } from 'constant/dateConfig';
import 'moment-timezone';

interface BackgroundProps {
  children: React.ReactNode;
  data: Record<string, SatelliteData>;
  onTimeRangeChange: (start: moment.Moment, end: moment.Moment) => void;
  selectedTimeRange: [moment.Moment, moment.Moment] | null;
  setZoomRange: (range: [moment.Moment, moment.Moment] | null) => void;
  zoomRange: [moment.Moment, moment.Moment] | null;
}

const formatTime = (time: moment.Moment) => {
  return {
    utc: time.utc().format('DDD HH:mm:ss [UTC]'),
    pacific: time.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss')
  };
};

const Background: React.FC<BackgroundProps> = ({ children, data, selectedTimeRange, onTimeRangeChange, setZoomRange, zoomRange }) => {
  const [currentTime, setCurrentTime] = useState(moment().utc());
  const { timeSetting, setTimeSetting } = useTimeSetting();

  const [currentlyContacted, setCurrentlyContacted] = useState<string[]>([]);
  const [nextContactTime, setNextContactTime] = useState<moment.Moment | null>(null);

  const { nextContactValues, colorList } = useTimeRangeContext();

  const config = useMemo(() => dateConfig[timeSetting], [timeSetting]);
  const { lengthTime, lengthUnit } = config; 

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment().utc()), 1000)
    return () => clearInterval(timer)
  }, [])

  const stationsWithinTimeRange = useMemo(() => {
    // Determine the range start and end
    const rangeStart = selectedTimeRange ? selectedTimeRange[0] : moment();
    const rangeEnd = selectedTimeRange 
      ? selectedTimeRange[1] 
      : moment().add(parseFloat(lengthTime), lengthUnit);

    let nextContact: moment.Moment | null = null;
    const currentContacts: string[] = [];

    const result = Object.entries(data).map(([satellite, satelliteData]) => {
      const tAos_list = satelliteData.tAos_Value.split(',').map(Number);
      const tLos_list = satelliteData.tLos_Value.split(',').map(Number);
      const station_list = satelliteData.station_Value.split(',');

      const filteredStations = tAos_list.reduce<Array<{ station: string; tAosTime: string; timeUntilContact: number; }>>((acc, tAos, index) => {
        const station = station_list[index];
        const tAosTime = moment(tAos * 1000);
        const tLosTime = moment(tLos_list[index] * 1000);

        // Check if either the contact start or end time is within the range
        const isWithinRange = 
        (tAosTime.isSameOrAfter(rangeStart) && tAosTime.isSameOrBefore(rangeEnd)) || // Contact is fully within range
        (tAosTime.isBefore(rangeStart) && tLosTime.isAfter(rangeStart)); // Contact started before range but hasn't ended

        if (isWithinRange) {
          const timeUntilContact = tAosTime.diff(rangeStart, 'milliseconds') / moment.duration(1, lengthUnit).asMilliseconds();

          // Update next contact time if this is the soonest future contact
          if (!nextContact || tAosTime.isBefore(nextContact)) {
            nextContact = tAosTime;
          }

          acc.push({
            station,
            tAosTime: tAosTime.toISOString(),
            timeUntilContact,
          });
        }

        return acc;
      }, []);

      return {
        satellite,
        filteredStations,
      };
    });

    // Update state variables
    setCurrentlyContacted([]);  // Reset currently contacted
    setNextContactTime(nextContact);

    return result;
  }, [data, selectedTimeRange, timeSetting, lengthTime, lengthUnit, zoomRange]);

  // Get a unique set of all stations within the current time range or setting
  const activeStationsSet = new Set(
    stationsWithinTimeRange.flatMap(({ filteredStations }) =>
      filteredStations.map(({ station }) => station)
    )
  );

  // Convert the set back to an array for rendering
  const activeStations = Array.from(activeStationsSet);

  // Get all stations
  const [showAllStations, setShowAllStations] = useState(false);
  const allStations = Object.keys(nameToMne);

  const toggleShowAllStations = () => {
    setShowAllStations(!showAllStations);
  };

  const getNextContactClass = () => {
    const nextContact = moment(nextContactValues * 1000);
    const minutesUntilContact = nextContact.diff(currentTime, 'minutes');

    if (minutesUntilContact <= 10) {
      return "red";
    } else if (minutesUntilContact <= 30) {
      return "orange";
    } else if (minutesUntilContact <= 60) {
      return "yellow";
    }
    return '';
  };

  const contactStyle = css`
    color: ${getNextContactClass()};
  `;

  const time = ['3 hrs', '12 hrs', '1 day', '3 days', '7 days'] as TimeKeys[];

  const currentTimeFormatted = formatTime(currentTime);

  return (
    <div className={reactAppWrapper}>
      <div className={timeInfoStyle}>
        <div className={timeSettingStyle}>
          {time.map((setting) => (
            <button
              key={setting}
              className={buttonStyle}
              style={{ backgroundColor: timeSetting === setting ? '#555' : '#333' }}
              onClick={() => {
                console.log('Setting time to:', setting);
                setTimeSetting(setting);
                // Optionally, you can reset selectedTimeRange or trigger onTimeRangeChange here
                const newRangeStart = moment();
                const newRangeEnd = moment().add(parseFloat(dateConfig[setting].lengthTime), dateConfig[setting].lengthUnit);
                onTimeRangeChange(newRangeStart, newRangeEnd);
                setZoomRange(null);
              }}
            >
              {setting}
            </button>
          ))}
        </div>

        <div>
        <div>
            <span className={fontStyle}>Pass Schedule Timeline</span>
          </div>
          <div className={cx(timeLabelStyle, css`
            align-items: flex-start`)}>
            <span>Current Contact:</span>
            {currentlyContacted.length === 0 ? 
            <div>
              - @ -
            </div> :
            <div className={css`
            display: flex;
            flex-direction: column;
            color: #00ff00;
            `}>
              {currentlyContacted.map((station) => (
                <span key={station}>{station}</span>
              ))}
            </div>
            }
          </div>
        </div>

        <div>
          <div className={timeLabelStyle}>
            <span>Current Time:</span>
            <span>{currentTimeFormatted.utc}</span>
          </div>
          <div className={timeLabelStyle}>
            <span>Next Contact:</span>
            <span className={contactStyle}>{nextContactTime ? nextContactTime.utc().format('DDD HH:mm:ss [UTC]') : ''}</span>
          </div>
        </div>
      </div>
      {children}
      <div className={legendStyle}>
      {(showAllStations ? allStations : activeStations)
        .sort((a, b) => a.localeCompare(b))
        .map((station) => {
          const color = findColorForStation(station, colorList);
          return (
            <div key={station} className={legendItemStyle}>
              <div style={{ width: 20, height: 20, backgroundColor: color }} />
              <span>{station}</span>
            </div>
          );
        })}
        <div className={legendItemStyle} onClick={toggleShowAllStations} style={{ cursor: 'pointer' }}>
          <span>&rsaquo;&rsaquo;&rsaquo;</span>
        </div>
      </div>
    </div>
  );
};

export default Background