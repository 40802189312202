import { decode } from "@msgpack/msgpack";
import { getTemplateSrv } from "@grafana/runtime";
import copy from 'copy-to-clipboard';
import { InnerData, LimitProp } from "./type";

export const nameToMne = {
  /**/
  "HW SOH TX Installed": "hwnbxarmplugst", /*HW SOH TX Group ENBL PLUG INSTALLED !*/
  "TX Installed": "nbxarmplugst", /*TX Group ENBL PLUG INSTALLED  !*/
  "Transponder PRI Pwr": "nbxppwst", /*Transponder power switch status: PRI !*/
  "Transponder RED Pwr": "nbtrpwst", /*Transponder power switch status: RED !*/
  "Transmitter PRI Pwr": "nbtxppw", /*Transmitter Power: PRI !*/
  "Transmitter RED Pwr": "nbtxrpw", /*Transmitter Power: RED !*/

  /*Transmitter*/
  "Transceiver downlink rate": "nbxdlrate", /*Transceiver Downlink Rate*/
  "Coherency Enabled": "nbxcohst", /*Coherency Enabled !*/ 
  "RF Enable": "nbxrfst", /*Status of RF Enable command !*/
  "Ranging Enabled": "nbxrngst", /*Ranging Channel Enabled  !*/
  "Conv Status": "nbxtxconvcodest", /*  Status of the Tx Conv Code Command !*/
  "Nrz Status": "nbxtxnrzconvst", /*Status of the Tx Nrz Conv On Command !*/
  "Transmitter Pwr": "nbxtxpw", /*Measurement of the transmitter power !*/
  "Transponder Fwd Pwr": "nbxfwdpw", /*Narrowband Transponder Fwd Power Out !*/
  "Transmitter 18/28": "nbxtxp18v", /*Transmitter 18V/28V secondary !*/
  "Transmitter Mode": "nbxtxopmodest", /*Transmitter Operating Mode  !*/

  /* Reciever */
  "Command Decrypt": "rxaescmd", /*Command Decrypt/Authenticate Error !*/
  "Command Auth Fail": "rxaescmderr", /*Command Authentication Fail Count !*/
  "Bit-Sync Lock": "nbxbslock", /*XCVR_UPLINK_SYNC_LOCK*/
  "Loop Stress": "rxloop", /*Loop Stress*/
  "Loop Signal power": "rxss", /*Signal power in the carrier tracking loop*/
  "Rx loop lock": "rxlock", /*Receiver carrier loop is locked !*/
  "Detector Lock": "rxbslock", /*Receiver Bit-Sync !*/
  "Rx Mode": "rxopmodest", /*Receiver Operating Mode !*/

  "Signal Detected": "nbxsigdetect", /*Signal has been detected*/

  "TE-Key Selected": "rxaestek", /*TE-Key Selected !*/
  "Pwr Amp Temp": "nbxpwampt", /* */
  "Mod Temp": "nbxmodt", /* */
  "Init Rout Status": "nbxinitdone", /* */
  "Init Rout Result": "nbxinitresult", /* */
  "Init Result": "nbxad9364calst", /* */
  "AD9364 En Machine State": "nbxensmst", /* */
  "Master Reset": "nbxrs", /* */
  "Reset Status": "nbxrsst", /* */

  "Cnd DS":"SWTFACPT", /* FSW Transfer Frame Accept Counter .This is counter of the commands successfully decoded by the radio and sent to the C&DH. */

  /* software/hardware command counters, accepted/rejected command counts */
  "FSW Apt Cnt":"swacpt", /* FSW Command Accept Counter  */
  "FSW Rj Cnt":"swrjct", /* FSW Command Reject Counter */
  "SACI Apt Cnt":"pwsaciacpt", /* SACI command accept counter */
  "HW Apt Cnt":"hwcmdacpt", /* HW SOH VC0 Command accept (Reference register SMOAB_CMD_ACCEPT(7:0)) */
  "HW Rj Cnt":"hwcmdrjct", /* HW SOH VC0 Command reject (Reference register SMOAB_CMD_REJECT(7:0)) */

  "CBM Active":"cbmblk", /* CBM Active Block Count */
  "CSM Status":"csmst", /* CSM status */
  "SBE Cnt":"moabsbecnt", /* The total number of Single Bit Error interrupts processed */
  "TMR Cnt":"moabtmrcnt", /* The total number of Flash TMR Errors Detected (Defaults to 1 on startup) */
  
  "SSMB WatchDog Task Status":"swwdssmbt", /* SSMB WatchDog Task Status */
  "SMOAB WatchDog ISR Task Status":"swwdmoabisrt", /* Watchdog MOAB ISR Task Status */
  "SMOAB WatchDog MBE Task Status":"swwdmoabmbet", /* Watchdog MOAB MBE Task Status */
  "SMOAB WatchDog SBE Task Status":"swwdmoabsbet", /* Watchdog MOAB SBE Task Status */
  "SMOAB WatchDog TLM Task Status":"swwdmoabtlmt", /* Watchdog MOAB Telemetry Task Status */
  
  /* SSMB */
  "SSMB Cmd Tx":"ssmbcmdtx", /* Number of commands transmitted to serial interfac */
  "SSMB Byte Tx":"ssmbbytetx", /* Number of bytes transmitted to serial interface */
  "SSMB Byte Rx":"ssmbtotalrx", /* Total Bytes received on serial interface */
  "SCI Pbk Time":"ssrsciencespbktime", /*  */

  /* SMOAB */
  "Moab Uplink Rand Status":"moabulrandst", /* Upllink Randomization Status */
  "Moab Downlink Rand Status":"moabdlrandst", /* Downlink Randomization Status */
  
  /* Quaternion */
  "Q1": "admattq1", /* Quaternion 1*/
  "Q2": "admattq2", /* Quaternion 2*/
  "Q3": "admattq3", /* Quaternion 3*/
  "Q4": "admattq4", /* Quaternion 4*/

  /*  */
  "SSOH Read State": "ssrssohPBK", /*  */
  "SSOH Pbk Time": "ssrssohspbktime", /*  */
  "SSR Read Status": "ssrsciencepbk", /*  */

  /*  */
  "Tx Switch LGA": "nbtxlga", /* NB Tx Switch Position at LGA */
  "Tx Switch MGA": "nbtxmga", /* NB Tx Switch Position at MGA */
}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value.toLowerCase() === mne.toLowerCase()) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export function generateMneString() {
  return Object.values(nameToMne)
    .map((value) => `mnes/\${ScName}/${value}/base64`)
    .join(';');
}

export const decodeFunc = (data: string) => {

  if (!data || data === "") {
    return null;
  }

  const binaryString = window.atob(data);
        
  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  // Decode the MessagePack data
  const decodedObject = decode(bytes);
  
  return decodedObject;
}

const variableName = 'ScName'; // Replace with your Grafana variable
export const source = getTemplateSrv().replace(`\${${variableName}}`);

export const handleCopyMneName = (mne: string) => {
  copy(mne);
};

export function determineLimit(stringValue: string, limit: LimitProp){

  const value = parseFloat(stringValue);

  if (limit.rl !== null && value < limit.rl){
    return 'rl';
  }else if (limit.rh !== null && value > limit.rh){
    return 'rh';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'rl';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'rh';
  }
  else {
    return "";
  }
}

export const checkOutOfLimitsEx = (data: InnerData) => {
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `rgb(246, 80, 80)`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#CA51EC`;
  }else {
    return `black`;
  }
};

export const checkOutOfLimitsHid = (data: InnerData) => {
  //console.log(data);
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `#d10808`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#A020F0`;
  }
  else {
    return `gray`;
  }
};

