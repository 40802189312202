import React,{useState} from 'react'
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import * as tbScheme from './TBScheme'; // Import your style
import { handleCopyMneName, determineColorNew } from 'utils/function';
import { TbProps } from 'utils/type';

type VisibleTooltips = { [key: string]: boolean };

const GlideCommTB: React.FC<TbProps> = ({data}) => {

  const [showPowerInfo, setShowPowerInfo] = useState(false);
  const [showPowerInfo1, setShowPowerInfo1] = useState(false);

  const handlePowerEllipseClick = () => {
    setShowPowerInfo(!showPowerInfo);

    setTimeout(() => {setShowPowerInfo(false)}, 1000);
  };

  const handlePowerEllipseClick1 = () => {
    setShowPowerInfo1(!showPowerInfo1);

    setTimeout(() => {setShowPowerInfo1(false)}, 1000);
  };

  const getColor = (power: string) => {
    if (power === "ON") {
        return "rgb(13, 84, 7)"; // Green for the selected and ON disk
    } else if (power === "OFF") {
        return "rgb(100,100,100)"; // Default gray color
    }
    else{
      return "rgb(100,100,100)";
    }
  }

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{top: 325,opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-2" style={{top: 450,opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{top: 125, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-2" style={{top: 480, opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{top: 275, opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{top: 375,opacity:0}}/>

      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={cx(scheme.textboxContainer, tbScheme[data.className as tbScheme.ClassKey])}>
        
        {data.power && <div style={{
          position: 'absolute',
          top: '0',
        }}>
          <svg width="50px" height="50">
          <g>
          <ellipse cx="25" cy="25" rx="15" ry="15" fill={getColor(data.power.telemetry)} stroke="6" pointerEvents="all" onClick={handlePowerEllipseClick}/>
            </g>
            </svg>
            </div>}

          {data.power1 && <div style={{
          position: 'absolute',
          top: '0',
          right: '0',
          }}>
            <svg width="50px" height="50">
          <g>
          <ellipse cx="25" cy="25" rx="15" ry="15" fill={getColor(data.power1.telemetry)} strokeWidth="6" stroke="black" pointerEvents="all" onClick={handlePowerEllipseClick1}/>
            </g>
            </svg>
            </div>}
        

        <p>{data.name}</p>

        <div className={cx(scheme.textboxItems, 'nowheel')}>
          
        {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData && innerData.dbData.live ? innerData.dbData.live : ""}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default GlideCommTB;
