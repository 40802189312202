import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { Node, ReactFlowInstance, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import Panel from '../module/Panel';
import CDH from '../module/CDH';
import GlideCommTB from './Textbox';
import Ground from 'module/Ground';
import Tele from 'module/Tele';
import Antennas from './Antennas';
import MOC from 'module/MOC';
import CustomEdge from './CustomEdge';
import GlideCommSBT from 'components/GlideCommSBT';
import BarChartComponent from './Bar';
import ModelViewer from './SCObj';
import IAU from './IAU';
import { css } from '@emotion/css';
import ScSketch from './ScSketch';
import VModeBox from './VModeBox';
import { useTimeRangeContext } from 'hooks/TimeRangeContext';
import PassIndicator from './PassIndicator';
import { useDrawer } from 'hooks/DrawerContext';
import { createTelemetryDictionary, getGapsData, getGroundData } from 'utils/dataProcessFunc';
import { createNodes } from 'utils/createNode';
import DrawerComponent from './Drawer';
import { LocationData, ProcessedData, Field, FlattenedItem } from 'utils/type';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type of unit
  live: string;
  mne: string;
  spacecraft: string;
};

type AppProps = {
  dbData: Field[];
  groundData: Field[];
  source: string;
  locationData: LocationData;
  width: number;
  height: number;
  influxData: ProcessedData | FlattenedItem[];
  limitData: Field[];
  // ... other props if there are any s s
};

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-eidpu, .react-flow__node-modelViewer{
  z-index: 6 !important;
}

.react-flow__node-panel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

const nodeTypes = { iau: IAU, modelViewer: ModelViewer, passIndicator:PassIndicator,scSketch: ScSketch, vmodeBox: VModeBox, ground: Ground, bar: BarChartComponent, glideCommSBT: GlideCommSBT, panel: Panel, antennas: Antennas, tele: Tele, cdh: CDH, glideCommTB: GlideCommTB, moc: MOC }
const edgeTypes = { customEdge: CustomEdge }

const GlideCommApp: React.FC<AppProps> = ({ dbData, groundData, source, locationData, width, height, influxData, limitData }) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  const { isDrawerOpen, onClose } = useDrawer();
  const playbackRef = useRef({ isProcessing: false });

  const groundList = useMemo(() => {
    return getGroundData(groundData);
  }, [groundData]);

  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView();
    }
  }
    , [width, height, reactFlowInstance]);

    const telemetryDictionary = useMemo(() => {
      return createTelemetryDictionary(dbData, limitData);
    }, [dbData, limitData]);
  
    const initialNodes = useMemo(() => {
      return createNodes(telemetryDictionary, pbTimeRef.current, pbLoopRef.current, groundList, locationData);
    }, [telemetryDictionary]);
   
    const [nodes, setNodes] = useState<Node[]>(initialNodes);
  
    const keyMnePairs = useMemo(() => {
      const pairs: Record<string, string> = {};
      
      nodes.forEach(node => {
        if (node.data.value) {
          Object.entries(node.data.value).forEach(([key, data]) => {
            // @ts-ignore
            pairs[key] = data.dbData?.mne || '';
          });
        }
      });
      
      return pairs;
    }, [nodes]);

    // Move data processing logic outside of render cycle
  const processDataRef = useRef(async (aggregatedData: TelemetryDictionary, totalLoops: number, influxDataInput: ProcessedData) => {
    if (playbackRef.current.isProcessing) return;
    playbackRef.current.isProcessing = true;

    try {
      for (let i = 0; i < totalLoops; i++) {
        if (!guiValues.current.pbisInPlayBack) {
          break;
        }

        const key = Object.keys(influxDataInput)[i];
        const selectedData = influxDataInput[key];
        pbTimeRef.current = key;
        const newValue = (i + 1) / totalLoops;
        pbLoopRef.current = newValue;

        // Update aggregatedData with new values
        selectedData.forEach(item => {
          if (aggregatedData[item.name]) {
            if (item.cnvValue !== '') {
              aggregatedData[item.name].telemetry = item.cnvValue;
            }
          } else {
            aggregatedData[item.name] = {
              telemetry: item.cnvValue,
              mne: item.mne,
              limit: '',
              unit: '',
              spacecraft: '',
              live: 'rgb(68, 169, 241)',
            };
          }
        });

        // Create nodes outside of render cycle
        const newNodes = createNodes(
          aggregatedData,
          pbTimeRef.current,
          pbLoopRef.current,
          groundList,
          locationData,
        );

        // Batch state update
        setNodes(newNodes);

        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    } finally {
      playbackRef.current.isProcessing = false;
    }
  });


    useEffect(() => {

      if (guiValues.current.pbisInPlayBack && !guiValues.current.isInJumpBack) {
        if (!playbackRef.current.isProcessing){
          let aggregatedData: TelemetryDictionary = {};
          const totalLoops = Object.keys(influxData).length;
          processDataRef.current(aggregatedData, totalLoops, influxData as ProcessedData);
        }
      } else if (guiValues.current.isInJumpBack && !guiValues.current.pbisInPlayBack) {
        let aggregatedData: TelemetryDictionary = {};
    
    // Check if influxData is flattened array using type guard
    if (Array.isArray(influxData)) {
      // Process flattened data
      influxData.forEach(item => {
        if (item.name) {
          if (aggregatedData[item.name]) {
            if (item.cnvValue !== '') {
              aggregatedData[item.name].telemetry = item.cnvValue;
            }
          } else {
            aggregatedData[item.name] = {
              telemetry: item.cnvValue,
              mne: item.mne,
              limit: '',
              unit: '',
              spacecraft: '',
              live: 'rgb(68, 169, 241)',
            };
          }
        }
      });

      // Create nodes with the aggregated data
      const newNodes = createNodes(
        aggregatedData,
        pbTimeRef.current,
        pbLoopRef.current,
        groundList,
        locationData,
      );

      // Update nodes state
      setNodes(newNodes);
    }
      }
      else {
        // When not in playback mode, update nodes with live data
        const newNodes = createNodes(
          telemetryDictionary, 
          pbTimeRef.current,
          pbLoopRef.current,
          groundList,
          locationData,
        );
        setNodes(newNodes); 
      }
    }, [
      influxData,
      telemetryDictionary,
      guiValues,
      groundList,
      locationData,
    ]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = { type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15 };

  const initialEdges = [
    { id: 'edge1', source: 'SMOAB', sourceHandle: 'source-right-1', target: 'S-Band Transponder', targetHandle: 'target-left-1', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'TtoG', source: 'Tele Subsystem', sourceHandle: 'source-right-1', target: 'Ground Subsystem', targetHandle: 'target-right-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge2', source: 'Ground Subsystem', sourceHandle: 'source-left-1', target: 'MOC', targetHandle: 'target-right-1', type: 'smoothstep', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },

    { id: 'edge3', source: 'MOC', sourceHandle: 'source-right-1', target: 'Ground Subsystem', targetHandle: 'target-left-1', type: 'smoothstep', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'GtoT', source: 'Ground Subsystem', sourceHandle: 'source-right', target: 'Tele Subsystem', targetHandle: 'target-right-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge4', source: 'S-Band Transponder', sourceHandle: 'source-left-1', target: 'SMOAB', targetHandle: 'target-right-2', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge5', source: 'S-Band Transponder', sourceHandle: 'source-right-1', target: 'ScSketch', targetHandle: 'target-left-1', type: 'customEdge', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge6', source: 'ScSketch', sourceHandle: 'source-left-1', target: 'S-Band Transponder', targetHandle: 'target-right-1', type: 'smoothstep', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge7', source: 'RAD750', sourceHandle: 'source-right', target: 'SMOAB', targetHandle: 'target-left-1', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge8', source: 'SSMB', sourceHandle: 'source-right', target: 'SMOAB', targetHandle: 'target-left-2', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },
    { id: 'edge9', source: 'SMOAB', sourceHandle: 'source-left-1', target: 'RAD750', targetHandle: 'target-right-1', type: 'straight', style: { stroke: 'white', strokeWidth: 5, strokeDasharray: '20, 20' }, markerEnd: arrow, zIndex: 100 },

  ];

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height, width }}>
        <ReactFlow
          onInit={onInit}
          nodes={nodes}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: options.X, y: options.Y, zoom: options.Zoom }}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}
        />
      </div>

      <DrawerComponent isOpen={isDrawerOpen} onClose={onClose} keyData={keyMnePairs} />

    </ReactFlowProvider>
  );

}

export default GlideCommApp;







