import React, { useCallback, useState } from 'react';
import { Field, SatelliteData, TestScheduleData } from './utils';
import { css } from '@emotion/css';
import TimeLine from './TimeLine';
import Background from './Background';
import { TimeSettingProvider } from './TimeSettingContext';
import BackgroundTestSchedule from './BackgroundTestSchedule';
import TimeLineTestSchedule from './TimeLineTestSchedule';

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  dbTestScheduleData: Field[];
  // ... other props if there are any
};

const reactAppWrapper = css`
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

  position: relative;
  background-color: black;
  color: #ffffff;
  font-size: 15px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
`;

const App: React.FC<AppProps> = ({ dbData, source, width, height, dbTestScheduleData }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<[moment.Moment, moment.Moment] | null>(null);
  const [zoomRange, setZoomRange] = useState<[moment.Moment, moment.Moment] | null>(null);

  const [selectedTimeRange1, setSelectedTimeRange1] = useState<[moment.Moment, moment.Moment] | null>(null);
  const [zoomRange1, setZoomRange1] = useState<[moment.Moment, moment.Moment] | null>(null);

  // Function to create a dictionary from dbData
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, SatelliteData> = {};

    const scNameColumn = dbData.find((column) => column.name === 'scName');
    const stationListColumn = dbData.find((column) => column.name === 'station_list');
    const tAosListColumn = dbData.find((column) => column.name === 'tAos_list');
    const tLosListColumn = dbData.find((column) => column.name === 'tLos_list');
    const trCodeListColumn = dbData.find((column) => column.name === 'trCode_list');

    if (!scNameColumn || !scNameColumn.values) {
      console.error('scName column not found or has no values');
      return telemetryDict;
    }

    scNameColumn.values.forEach((scName, index) => {
      const station_Value = stationListColumn ? stationListColumn.values[index] : '';
      const tAos_Value = tAosListColumn ? tAosListColumn.values[index] : '';
      const tLos_Value = tLosListColumn ? tLosListColumn.values[index] : '';
      const trCode_Value = trCodeListColumn ? trCodeListColumn.values[index] : '';

      telemetryDict[scName] = {
        tAos_Value,
        tLos_Value,
        trCode_Value,
        station_Value,
      };
    });

    return telemetryDict;
  }, [dbData]);

  const telemetryDictionary = createTelemetryDictionary();

  // Function to create a dictionary from dbData
  const createTestSchduleData = useCallback(() => {
    const telemetryDict: Record<string, TestScheduleData> = {};

    const scNameColumn = dbTestScheduleData.find((column) => column.name === 'ScName');
    const tAosListColumn = dbTestScheduleData.find((column) => column.name === 'tAos_list');
    const tLosListColumn = dbTestScheduleData.find((column) => column.name === 'tLos_list');
    const testDescriptionListColumn = dbTestScheduleData.find((column) => column.name === 'testDescription_list');

    if (!scNameColumn || !scNameColumn.values) {
      console.error('scName column not found or has no values');
      return telemetryDict;
    }

    scNameColumn.values.forEach((scName, index) => {
      const tAos_Value = tAosListColumn ? tAosListColumn.values[index] : '';
      const tLos_Value = tLosListColumn ? tLosListColumn.values[index] : '';
      const testDescription_Value = testDescriptionListColumn ? testDescriptionListColumn.values[index] : '';

      telemetryDict[scName] = {
        tAos_Value,
        tLos_Value,
        testDescription_Value,
      };
    });

    return telemetryDict;
  }, [dbData]);

  const testScheduleData = createTestSchduleData();

  // Callback function to handle selected time range
  const handleTimeRangeChange = (start: moment.Moment, end: moment.Moment) => {
    setSelectedTimeRange([start, end]);
    console.log('Selected Time Range:', start, end); // Debug log
  };

    // Callback function to handle selected time range
    const handleTimeRangeChange1 = (start: moment.Moment, end: moment.Moment) => {
      setSelectedTimeRange1([start, end]);
      console.log('Selected Time Range:', start, end); // Debug log
    };

  return (
    <div className={reactAppWrapper} style={{ height, width }}>
      <TimeSettingProvider>
        <Background
          data={telemetryDictionary}
          selectedTimeRange={selectedTimeRange}
          onTimeRangeChange={handleTimeRangeChange}
          setZoomRange={setZoomRange}
          zoomRange={zoomRange}
        >
          <TimeLine
            data={telemetryDictionary}
            selectedTimeRange={selectedTimeRange}
            onTimeRangeChange={handleTimeRangeChange}
            setZoomRange={setZoomRange}
            zoomRange={zoomRange}
          />
        </Background>
        <BackgroundTestSchedule
          data={testScheduleData}
          selectedTimeRange={selectedTimeRange1}
          onTimeRangeChange={handleTimeRangeChange1}
          setZoomRange={setZoomRange1}
          zoomRange={zoomRange1}
        >
          <TimeLineTestSchedule
            data={testScheduleData}
            selectedTimeRange={selectedTimeRange1}
            onTimeRangeChange={handleTimeRangeChange1}
            setZoomRange={setZoomRange1}
            zoomRange={zoomRange1}
          />
        </BackgroundTestSchedule>
      </TimeSettingProvider>
    </div>
  );
};

export default App;
