import React from "react";
import { Handle, Position } from "reactflow";
import { css, cx } from '@emotion/css';
import { InnerData, TLM } from "utils/type";
import * as scheme from './EPSScheme';

interface HeaterProp {
  data: {
    name: string;
    classname: string;
    value: InnerData;
  };
}

const Heater: React.FC<HeaterProp> = ({ data }) => {

  const heaterContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `

  const getColorPwr = (value: TLM) => {
    if (value && value.telemetry.toLowerCase() === "ON".toLowerCase()){
      return "green"
    }
    else{
      return "rgb(100,100,100)"
    }
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom-1" style={{opacity:0, left: 450}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom-2" style={{opacity:0, left: 250}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={cx(scheme.textboxContainer, css`width: 575px;`)}>
        <p>{data.name}</p>

        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: space-evenly;
            width: 575px;
          `}
        >
          {data.value && Object.entries(data.value).map(([key, innerData], index) => (
            <div
              key={key}
            >
      <div className={heaterContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="240px" height="60px" viewBox="-0.5 -0.5 240 60">
            <defs />
            <g>
            <rect x="60" y="0" width="60" height="60" fill="rgb(38,38,38)" stroke={getColorPwr(innerData.dbData)} strokeWidth={6} pointerEvents="all" />
            <rect x="120" y="0" width="60" height="60" fill="rgb(38,38,38)" stroke={getColorPwr(innerData.dbData)} strokeWidth={6} pointerEvents="all" />
              <path d="M 180 30 L 240 30" fill="rgb(38,38,38)" stroke="white" strokeWidth={6} strokeMiterlimit="10" pointerEvents="stroke" />
              <path d="M 0 30 L 60 30" fill="rgb(38,38,38)" stroke="white" strokeWidth={6} strokeMiterlimit="10" pointerEvents="stroke" />
            </g>
          </svg>

          <span>{key}</span>

      </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Heater;
