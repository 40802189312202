import React, { useMemo, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { InnerData, TLM } from 'utils/type';

interface ScSketchProp {
  data: {
    name: string;
    value: InnerData;
    lgaSwitch: TLM;
    mgaSwitch: TLM;
  }
}

// Memoized Handles component
const Handles = React.memo(() => {
  const baseStyle = useMemo(() => ({ opacity: 0 }), []);
  
  return (
    <>
      <Handle type="target" position={Position.Top} id="target-top" style={baseStyle} />
      <Handle type="target" position={Position.Left} id="target-left" style={baseStyle} />
      <Handle type="target" position={Position.Left} id="target-left-1" style={{...baseStyle, top: 350}} />
      <Handle type="target" position={Position.Right} id="target-right" style={baseStyle} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={baseStyle} />
      
      <Handle type="source" position={Position.Top} id="source-top" style={baseStyle} />
      <Handle type="source" position={Position.Left} id="source-left" style={baseStyle} />
      <Handle type="source" position={Position.Left} id="source-left-1" style={{...baseStyle, top: 500}} />
      <Handle type="source" position={Position.Right} id="source-right" style={baseStyle} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={baseStyle} />
    </>
  );
});

// Memoized SVG components
const TopView = React.memo(({ lgaColor }: { lgaColor: string }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    width="500px" 
    height="410px" 
    viewBox="-0.5 -0.5 500 410"
  >
    <g>
      <polygon 
        points="5,5 495,5 495,200 400,405 100,405 5,200" 
        fill="#606060" 
        stroke="rgb(13,39,45)" 
        strokeWidth="6"
      />
      <g>
        <rect 
          x="305" 
          y="305" 
          width="60" 
          height="60" 
          fill={lgaColor} 
          stroke="rgb(13,39,45)" 
          strokeWidth="5" 
          strokeMiterlimit="10" 
          pointerEvents="all"
        />
        <text x="310" y="330" fill="white" fontSize="25px">Z-</text>
        <text x="310" y="360" fill="white" fontSize="25px">LGA</text>
      </g>
    </g>
    <text x="200" y="220" fill="white" fontSize="45px">+ Y</text>
  </svg>
));

const BottomView = React.memo(({ lgaColor, mgaColor }: { lgaColor: string; mgaColor: string }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    width="400px" 
    height="360px" 
    viewBox="-0.5 -0.5 400 360"
  >
    <g>
      <rect 
        x="5" 
        y="5" 
        width="390" 
        height="300" 
        fill="#606060" 
        stroke="rgb(13,39,45)" 
        strokeWidth="6" 
        strokeMiterlimit="10" 
        pointerEvents="all"
      />
      <g>
        <rect 
          x="275" 
          y="195" 
          width="95" 
          height="95" 
          fill={mgaColor} 
          stroke="rgb(13,39,45)" 
          strokeWidth="6" 
          strokeMiterlimit="10" 
          pointerEvents="all"
        />
        <text x="281" y="238" fill="white" fontSize="45px">MGA</text>
        <text x="296" y="275" fill="white" fontSize="45px">Tx</text>
      </g>
      <g>
        <rect 
          x="35" 
          y="225" 
          width="60" 
          height="60" 
          fill={lgaColor} 
          stroke="rgb(13,39,45)" 
          strokeWidth="5" 
          strokeMiterlimit="10" 
          pointerEvents="all"
        />
        <text x="40" y="255" fill="white" fontSize="25px">N-</text>
        <text x="40" y="280" fill="white" fontSize="25px">LGA</text>
      </g>
    </g>
    <text x="145" y="170" fill="white" fontSize="45px">- Y</text>
  </svg>
));

const ScSketch: React.FC<ScSketchProp> = React.memo(({ data }) => {
  // Memoized color calculation function
  const annColor = useCallback((annName: string): string => {
    if (!data.lgaSwitch || !data.mgaSwitch) {
      return 'gray';
    }
    
    const lgaSwitch = data.lgaSwitch.telemetry.toLowerCase();
    const mgaSwitch = data.mgaSwitch.telemetry.toLowerCase();
    const annNameLower = annName.toLowerCase();
    
    return (lgaSwitch.includes(annNameLower) && mgaSwitch.includes(annNameLower)) 
      ? 'green' 
      : 'gray';
  }, [data.lgaSwitch, data.mgaSwitch]);

  // Memoize color values
  const colors = useMemo(() => ({
    lga: annColor('lga'),
    mga: annColor('mga')
  }), [annColor]);

  // Memoize container style
  const containerStyle = useMemo(() => ({
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
  }), []);

  return (
    <div>
      <Handles />
      <div style={containerStyle}>
        <TopView lgaColor={colors.lga} />
        <BottomView lgaColor={colors.lga} mgaColor={colors.mga} />
      </div>
    </div>
  );
});

export default ScSketch;