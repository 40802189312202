
import { css, cx } from '@emotion/css';
import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './EPSScheme'; // Import your styles
import * as htbScheme from './EpsHTBScheme';
import { determineColorNew, handleCopyMneName } from 'utils/function';
import { InnerDataValue, VisibleTooltips } from 'utils/type';

type PowerCurrData = {
  Power: InnerDataValue;
  Curr: InnerDataValue;
};

type InnerData1dfad = {
  [componentName: string]: PowerCurrData;
};

type PayloadProps = {
  data: {
    name: string;
    value: InnerData1dfad;
    className: string;
    id: string;
  };
};

const Payload: React.FC<PayloadProps> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});
  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));

    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };
  
  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left-1" style={{ opacity: 0, top: 100 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      {/* <Handle type="target" position={Position.Right} id="target-right-1" style={{ opacity: 0, top:30  }} /> */}

      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom-4" style={{opacity:0, left: 850}}/>

      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top-1" style={{ opacity: 0, left: 400 }} />
      <Handle type="source" position={Position.Top} id="source-top-2" style={{ opacity: 0, left: 500 }} />
      {/* <Handle type="source" position={Position.Top} id="source-top-1" style={{ opacity: 0, left: 950 }} /> */}
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left-1" style={{ opacity: 0, top:25 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

      <div className={cx(scheme.textboxContainer, htbScheme[data.className as htbScheme.ClassKey])}>
        <p>{data.name}</p>
        <div className={scheme.tbHorContainer}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={cx(scheme.tbValueContainer, css``)} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}&nbsp;Power/Curr:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.Curr.dbData.mne)}>{innerData.Curr.dbData?.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.Curr.dbData?.fname}</span>
              </div>
              
              <div className={css`
              display: flex;
              gap: 10px;
              `}>
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="-0.5 -0.5 50 50">
                  <circle cx="25" cy="25" r="21"
                    fill={innerData.Power.dbData?.telemetry === 'ON' ? 'green' : 'gray'}
                    stroke="white"
                    strokeWidth="4"
                  />
                </svg>
                  </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.Curr.dbData?.live}`,
                    scheme.classMap[determineColorNew(innerData.Curr.dbData?.limit)], 
                  )}
                >
                  {innerData.Curr.dbData?.telemetry}
                </span>
                {innerData.Curr.dbData && innerData.Curr.dbData.unit && <span className={scheme.svgUnit}>{innerData.Curr.dbData?.unit}</span>}
              </div>
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.Curr.dbData.mne, sc: innerData.Curr.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Payload;
